import {
  SEARCHKIT_CHANGE_INDEX,
  SEARCHKIT_CHANGE_URL,
  SEARCHKIT_LISTEN,
  SEARCHKIT_UNLISTEN,
} from "./types";

export const searchkitListen = () => {
  return {
    type: SEARCHKIT_LISTEN,
  };
};

export const searchkitUnlisten = () => {
  return {
    type: SEARCHKIT_UNLISTEN,
  };
};

export const searchkitChangeUrl = (url: string) => {
  return {
    type: SEARCHKIT_CHANGE_URL,
    payload: { url },
  };
};

export const searchkitChangeIndex = (index: number, reload = true) => {
  return {
    type: SEARCHKIT_CHANGE_INDEX,
    payload: index,
    reload,
  };
};
