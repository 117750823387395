import { combineReducers } from "redux";
import authReducer from "./app/auth/duck";
import detailReducer from "./app/search/detail/duck/reducer";
import searchReducer from "./app/search/duck/reducer";

const reducer = combineReducers({
  auth: authReducer,
  search: searchReducer,
  detail: detailReducer,
});

export type RootState = ReturnType<typeof reducer>;

export default reducer;
