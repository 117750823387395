import apiData from "../../../../api/apiPantry";
import { ApiData, ApiResponse } from "../types";

// Set default User object
const User = {
  id: 0,
  name: "",
  access_level: 0,
  email: "",
  sub: "",
};

/**
 *
 * @param {String} sub Value from Auth0
 * @returns User object with id and name
 */
export const getUser = (sub?: string) => {
  // async function that calls apiData to get user id and name
  async function assignProps(sub?: string) {
    try {
      const response = await (apiData as ApiData)
        .getUserID(sub)
        .then((response: ApiResponse) => response);
      User.id = response.data.id;
      User.name = response.data.name;
      User.access_level = response.data.access_level;
      User.email = response.data.email;
    } catch (error: any) {
      if (error.response.status !== 404) {
        // If a 404, it'll be handled by grabUser
        throw error;
      }
    }
  }

  if (sub) {
    User.sub = sub;
    assignProps(sub);
  }

  return User;
};
