import { lazy, StrictMode } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import { store } from "./app/store";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import AuthedElement from "./auth/AuthedElement";
import "./index.scss";

const Search = lazy(() => import("./app/search/SearchContainer"));
const NestedContainer = lazy(() => import("./app/nested/NestedContainer"));
// TODO: upgrade to React 18 renderer, breaks graphs

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <Provider store={store}>
          <Routes>
            <Route element={<AuthedElement element={App} />}>
              <Route path="/" element={<Search />} />
              <Route path="/nested/*" element={<NestedContainer />} />
            </Route>
          </Routes>
        </Provider>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById("root")
);