import { SearchkitManager } from "searchkit";
import { createHistory } from "../history";
import { AnalyzeESTransport } from "../searchkit/AnalyzeESTransport.js";

const transport = new AnalyzeESTransport(process.env.REACT_APP_API, {
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
  setTimeout: 10000,
});

const searchkit = new SearchkitManager(process.env.REACT_APP_API!, {
  createHistory,
  transport,
  searchOnLoad: false,
});

searchkit.setQueryProcessor((plainQueryObject: any) => {
  return plainQueryObject;
});


export default searchkit;
