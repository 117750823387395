import { Suspense } from "react";
import { connect } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { Layout } from "searchkit";
import "./App.scss";
import AccessDenied from "./auth/AccessDenied";
import LoadingSpinner from "./utils/LoadingSpinner";

function App() {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);

  /* istanbul ignore next */
  if (urlParams.get("error") === "access_denied") {
    return <AccessDenied />;
  }

  return (
    <Layout>
      <Suspense fallback={<LoadingSpinner />}>
        <Outlet />
      </Suspense>
    </Layout>
  );
}

export default connect(null)(App);
