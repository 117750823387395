import axios from "axios";
import { User } from "./types";

const userInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: 10000,
});

export function getAllUsers() {
  return {
    json: {
      method: "GET",
      url: `/user/all`,
    },
    instance: userInstance,
    protected: true,
  };
}

export function createUser(userJson: User) {
  return {
    json: {
      method: "POST",
      url: `/user/create`,
      data: userJson,
    },
    instance: userInstance,
    protected: true,
  };
}

export function getUserByID(id: number) {
  return {
    json: {
      method: "GET",
      url: `/user/${id}`,
      data: { id: id },
    },
    instance: userInstance,
    protected: true,
  };
}

export function getUserSearches(id: number) {
  return {
    json: {
      method: "GET",
      url: `/user/searches/${id}`,
      data: { id: id },
    },
    instance: userInstance,
    protected: true,
  };
}

export function getUserID(sub: string) {
  return {
    json: {
      method: "GET",
      url: `/user/search/${sub}`,
      data: { sub: sub },
    },
    instance: userInstance,
    protected: true,
  };
}

export function getAccessLevel(id: number) {
  return {
    json: {
      method: "GET",
      url: `/user/access_level/${id}`,
      data: { id: id },
    },
    instance: userInstance,
    protected: true,
  };
}

export function setAccessLevel([id, access_level]: [number, number]) {
  return {
    json: {
      method: "PATCH",
      url: `/user/set_access_level/${id}?access_level=${access_level}`,
      params: {
        access_level: access_level,
      },
      data: { id: id, access_level: access_level },
    },
    instance: userInstance,
    protected: true,
  };
}

export function getUsersByEmail(email: string) {
  return {
    json: {
      method: "GET",
      url: `/user/get_by_email/${email}`,
      data: { email: email },
    },
    instance: userInstance,
    protected: true,
  };
}
