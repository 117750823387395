import { types as searchkitTypes } from "../../../searchkit/redux";
import { getIds, normalize } from "../../../utils";
import * as types from "./types";

const INITIAL_STATE = {
  allIds: [],
  byId: {},
  currentSearchId: null,
  isLoading: false,
  isEdited: false,
  currentIndex: "claims",
  error: null,
};

function addSearchEntry(state: any, action: any) {
  const { id } = action.payload;
  return {
    ...state,
    [id]: action.payload,
  };
}

function removeSearchEntry(state: any, action: any) {
  const { id } = action.payload;
  const newState = {
    ...state,
  };
  delete newState[id];
  return newState;
}

function addSearchId(state: any, action: any) {
  const { id } = action.payload;
  return state.concat(id);
}

function removeSearchId(state: any, action: any) {
  const { id } = action.payload;
  return state.filter((item: any) => item !== id);
}

function searchReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case types.GET_SEARCHES_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.GET_SEARCHES_SUCCESS: {
      return {
        ...state,
        byId: normalize(action.payload),
        allIds: getIds(action.payload),
        isLoading: false,
      };
    }
    case types.GET_SEARCHES_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case types.CREATE_SEARCH_SUCCESS: {
      return {
        ...state,
        byId: addSearchEntry(state.byId, action),
        allIds: addSearchId(state.allIds, action),
        currentSearchId: action.payload.id,
        isEdited: false,
      };
    }
    case types.UPDATE_SEARCH_SUCCESS: {
      return {
        ...state,
        byId: addSearchEntry(state.byId, action),
        isEdited: false,
      };
    }
    case types.DELETE_SEARCH_SUCCESS: {
      return {
        ...state,
        byId: removeSearchEntry(state.byId, action),
        allIds: removeSearchId(state.allIds, action),
      };
    }
    case types.CHANGE_CURRENT_SEARCH: {
      return {
        ...state,
        currentSearchId: action.payload.id,
        isEdited: false,
      };
    }
    case searchkitTypes.SEARCHKIT_UPDATE: {
      let isEdited = false;
      // @ts-ignore
      const currentSearch = state.byId[state.currentSearchId];
      if (currentSearch) {
        isEdited = currentSearch.url !== action.payload.url;
      }
      return {
        ...state,
        isEdited,
      };
    }
    case searchkitTypes.SEARCHKIT_CHANGE_INDEX: {
      return {
        ...state,
        currentIndex: action.payload.key,
      };
    }
    default:
      return state;
  }
}

export default searchReducer;
