import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import AuthHelper from "./auth-helper";

const Auth0ProviderWithHistory = (props: React.PropsWithChildren) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const connection = process.env.REACT_APP_AUTH0_CONNECTION;

  // Initialize Auth0
  const navigate = useNavigate();

  const defaultUri = window.location.origin + "/";

  //TODO: Replace any
  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || defaultUri);
  };

  return (
    <Auth0Provider
      domain={domain!}
      clientId={clientId!}
      authorizationParams={{
        audience: "magnify-server.magmutual.com",
        scope: "openid profile email offline_access full_access",
        connection: connection,
        redirect_uri: defaultUri,
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      legacySameSiteCookie={false}
    >
      <AuthHelper>{props.children}</AuthHelper>
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
