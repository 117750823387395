import axios from "axios";
import { getUser } from "../app/search/header/actionbar/User";
import { PagesAnalyze, PagesSearch } from "./types";

const pagesInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: 10000,
});

export function pagesSearch(pagesJson: PagesSearch) {
  const userID = getUser();
  pagesJson["user_id"] = userID["id"];
  return {
    json: {
      method: "POST",
      url: `/pages/_search`,
      data: pagesJson,
    },
    instance: pagesInstance,
    protected: true,
  };
}

export function pagesAnalyze(pagesJson: PagesAnalyze) {
  const userID = getUser();
  pagesJson["user_id"] = userID["id"];
  return {
    json: {
      method: "POST",
      url: `/pages/_analyze`,
      data: pagesJson,
    },
    instance: pagesInstance,
    protected: true,
  };
}
