export const GET_SEARCHES_REQUEST = "GET_SEARCHES_REQUEST";
export const GET_SEARCHES_SUCCESS = "GET_SEARCHES_SUCCESS";
export const GET_SEARCHES_FAILED = "GET_SEARCHES_FAILED";
export const CREATE_SEARCH_REQUEST = "CREATE_SEARCH_REQUEST";
export const CREATE_SEARCH_SUCCESS = "CREATE_SEARCH_SUCCESS";
export const CREATE_SEARCH_FAILED = "CREATE_SEARCH_FAILED";
export const UPDATE_SEARCH_REQUEST = "UPDATE_SEARCH_REQUEST";
export const UPDATE_SEARCH_SUCCESS = "UPDATE_SEARCH_SUCCESS";
export const UPDATE_SEARCH_FAILED = "UPDATE_SEARCH_FAILED";
export const DELETE_SEARCH_REQUEST = "DELETE_SEARCH_REQUEST";
export const DELETE_SEARCH_SUCCESS = "DELETE_SEARCH_SUCCESS";
export const DELETE_SEARCH_FAILED = "DELETE_SEARCH_FAILED";
export const CHANGE_CURRENT_SEARCH = "CHANGE_CURRENT_SEARCH";
