import "./LoadingSpinner.scss";

interface LoadingSpinnerProps {
  message?: string;
}

export default function LoadingSpinner({ message }: LoadingSpinnerProps) {
  if (message) {
    return (
      <>
        <div className="sk-spinning-loader" />
        <p className="loader-message">{message}</p>
      </>
    );
  }
  return <div className="loader" />;
}
