import { useAuth0 } from "@auth0/auth0-react";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import React, { useEffect } from "react";

function AuthHelper(props: React.PropsWithChildren) {
  // Initialize Auth0
  const { user } = useAuth0();

  useEffect(() => {
    if (user) {
      LogRocket.init(process.env.REACT_APP_LOGROCKET_KEY!, {
        release: process.env.REACT_APP_VESION,
      });
      LogRocket.identify(user.sub!, {
        name: user.name!,
        email: user.email!,
      });
      setupLogRocketReact(LogRocket);
    }
  }, [user]);
  return <>{props.children}</>;
}

export default AuthHelper;
