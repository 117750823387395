import * as types from "./types";

const INITIAL_STATE = {
  selectedResult: null,
  pages: [],
  documents: [],
  error: null,
  isLoading: false,
};

function appReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case types.SET_SELECTED_RESULT:
      return {
        ...state,
        selectedResult: action.payload,
      };
    case types.GET_PAGES_SUCCESS:
      return {
        ...state,
        pages: action.payload.hits.hits,
        documents: action.payload.aggregations.by_doc_type.buckets,
      };
    case types.GET_PAGES_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default appReducer;
