import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import searchkit from "./api/searchkit";
import rootReducer, { RootState } from "./reducer";
import { createSearchkitMiddleware } from "./searchkit/redux";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers: typeof compose =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(preloadedState?: RootState) {
  const enhancers = composeEnhancers(
    applyMiddleware(thunk, createSearchkitMiddleware(searchkit))
  );

  const store = createStore(rootReducer, preloadedState, enhancers);

  /* istanbul ignore next */
  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./reducer", () => store.replaceReducer(rootReducer));
  }

  return store;
}
