let states: { [key: string]: any } = {};

function getState(key: string): any {
  return states[key];
}

function setState(key: string, newState: any): any {
  states[key] = newState;
  return states[key];
}

export { getState, setState };
