import {
  FETCH_USER_FAILED,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
} from "./types";

// Sets the initial state to be null
const INITIAL_STATE = {
  user: null,
  isLoadingUser: false,
};

function authReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        ...state,
        isLoadingUser: true,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isLoadingUser: false,
      };
    case FETCH_USER_FAILED:
      return {
        ...state,
        user: null,
        isLoadingUser: false,
      };
    default:
      return state;
  }
}

export default authReducer;
