import defaults from "lodash/defaults";
import { AxiosESTransport } from "searchkit";
import { getUser } from "../app/search/header/actionbar/User";

//Performs an Axios Request based on the provided option for the constructor
//Called from SavedSearches

export class AnalyzeESTransport extends AxiosESTransport {
  constructor(host, options) {
    options = defaults(options, {
      headers: {},
      indexPath: "",
      analyzeUrlPath: "/_analyze",
      searchUrlPath: "/_search",
    });

    super(host, options);
  }

  //Performs the Search Query
  search(query, index = this.options.indexPath) {
    const userID = getUser();
    query["user_id"] = userID["id"];
    return this.axios
      .post(index + this.options.searchUrlPath, query)
      .then(this.getData);
  }

  //Analyze the query results based on provided criteria
  analyze(query, index = this.options.indexPath) {
    const userID = getUser();
    query["user_id"] = userID["id"];
    return this.axios
      .post(index + this.options.analyzeUrlPath, query)
      .then(this.getData);
  }
}
