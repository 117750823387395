import axios from "axios";
import axiosRateLimit from "axios-rate-limit";
import { Search } from "./types";

const searchInstance = axiosRateLimit(
  axios.create({
    baseURL: process.env.REACT_APP_API,
    timeout: 10000,
  }),
  { maxRPS: 3 }
);

//Search Function

//TODO: CREATE SEARCH ENDPOINT TO GET ALL SEARCHES BY USER_ID
export function getAllSearches() {
  return {
    json: {
      method: "GET",
      url: `/search/all`,
      json: {},
    },
    instance: searchInstance,
    protected: true,
  };
}

export function createNewSearch(searchJson: Search) {
  return {
    json: {
      method: "POST",
      url: `/search/create`,
      data: searchJson,
    },
    instance: searchInstance,
    protected: true,
  };
}

export function getSearchByID(id: number) {
  return {
    json: {
      method: "GET",
      url: `/search/${id}`,
      data: { id: id },
    },
    instance: searchInstance,
    protected: true,
  };
}

export function updateNewSearch(searchJson: Search) {
  return {
    json: {
      method: "PUT",
      url: `/search/update`,
      data: searchJson,
    },
    instance: searchInstance,
    protected: true,
  };
}

export function deleteNewSearch(searchJson: Search) {
  return {
    json: {
      method: "DELETE",
      url: `/search/delete`,
      data: searchJson,
    },
    instance: searchInstance,
    protected: true,
  };
}
