export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const numberFormat = (num: number, fixed = 0) => {
  return `${Number(num).toLocaleString("en-US", {
    maximumFractionDigits: fixed,
  })}`;
};

export const numberAbbreviate = (num: number, fixed: number) => {
  if (num === null) {
    return null;
  } // terminate early
  if (num === 0) {
    return "0";
  } // terminate early
  fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
  const b = num.toPrecision(2).split("e"), // get power
    k =
      b.length === 1 ? 0 : Math.floor(Math.min(Number(b[1].slice(1)), 14) / 3), // floor at decimals, ceiling at trillions
    c =
      k < 1
        ? num.toFixed(0 + fixed)
        : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
    d = Number(c) < 0 ? c : Math.abs(Number(c)), // enforce -0 is 0
    e = d + ["", "K", "M", "B", "T"][k]; // append power
  return e;
};

export function currencyFormatter(value: number) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumSignificantDigits: 1,
  }).format(value);
}

export const removeInitialCode = (description: string) => {
  if (description) {
    return description.replace(/\d+\s+/g, "");
  } else {
    return null;
  }
};

export function normalize(arr: Array<any>) {
  return arr.reduce((obj, item) => {
    obj[item.id] = item;
    return obj;
  }, {});
}

export function getIds(arr: Array<any>) {
  return arr.map((item) => item.id);
}

export function formatStringArray(arr: Array<string>) {
  const formattedArray = arr.reduce((str, val) => {
    str += fieldToTitle(val) + ", ";
    return str;
  }, "");
  return formattedArray.substring(0, formattedArray.length - 2);
}

export function fieldToTitle(field: string) {
  if (field !== null) {
    return field.replace(/_/g, " ");
  } else return field;
}
